import React, { useState } from 'react'
import alg1 from '../../docpdf/X SSC ALG PRAC PAPER 1.pdf'
import alg2 from '../../docpdf/X SSC ALG PRAC PAPER 2.pdf'
import eng1 from '../../docpdf/X SSC ENG PRAC PAPER 1.pdf'
import eng2 from '../../docpdf/X SSC ENG PRAC PAPER 2.pdf'
import geog1 from '../../docpdf/X SSC GEOG PRAC PAPER 1.pdf'
import geog2 from '../../docpdf/X SSC GEOG PRAC PAPER 2.pdf'
import geom1 from '../../docpdf/X SSC GEOM PRAC PAPER 1.pdf'
import geom2 from '../../docpdf/X SSC GEOM PRAC PAPER 2.pdf'
import hist1 from '../../docpdf/X SSC HIST PRAC PAPER 1.pdf'
import hist2 from '../../docpdf/X SSC HIST PRAC PAPER 2.pdf'
import mar1 from '../../docpdf/X SSC MARATHI PRAC PAPER 1.pdf'
import mar2 from '../../docpdf/X SSC MARATHI PRAC PAPER 2.pdf'
import fre1 from '../../docpdf/X-SSC-FRENCH 50(PAPER 1).pdf'
import fre2 from '../../docpdf/X-SSC-FRENCH 50(PAPER 2).pdf'
import hindi1 from '../../docpdf/X-SSC-HINDI 50(PAPER 1).pdf.pdf'
import hindi2 from '../../docpdf/X-SSC-HINDI 50(PAPER 2).pdf'
import hindi3 from '../../docpdf/X-SSC-HINDI 100 (PAPER 1).pdf'
import hindi4 from '../../docpdf/X-SSC-HINDI 100 (PAPER 2).pdf'
import san1 from '../../docpdf/X-SSC-SANSKRIT 50 (PAPER 1).pdf'
import san2 from '../../docpdf/X-SSC-SANSKRIT 100 (PAPER 1).pdf'
import san3 from '../../docpdf/X-SSC-SANSKRIT 100 (PAPER 2).pdf'
import sci1 from '../../docpdf/X-SSC-SCIENCE 1 (PAPER 1).pdf'
import sci2 from '../../docpdf/X-SSC-SCIENCE 1 (PAPER 2).pdf'
import sci3 from '../../docpdf/X-SSC-SCIENCE 2(PAPER 1).pdf'
import sci4 from '../../docpdf/X-SSC-SCIENCE 2 (PAPER 2).pdf'

export default function Sscpaper() {
    const [data, setData] = useState([
        { "id": 1, "heading": "X SSC ALGEBRA PRACTICE PAPER 1", "pdf": alg1 },
        { "id": 2, "heading": "X SSC ALGEBRA PRACTICE PAPER 2", "pdf": alg2 },
        { "id": 3, "heading": "X SSC ENGLISH PRACTICE PAPER 1", "pdf": eng1 },
        { "id": 4, "heading": "X SSC ENGLISH PRACTICE PAPER 2", "pdf": eng2 },
        { "id": 5, "heading": "X SSC GEOGRAPHY PRACTICE PAPER 1", "pdf": geog1 },
        { "id": 6, "heading": "X SSC GEOGRAPHY PRACTICE PAPER 2", "pdf": geog2 },
        { "id": 7, "heading": "X SSC GEOMETRY PRACTICE PAPER 1", "pdf": geom1 },
        { "id": 8, "heading": "X SSC GEOMETRY PRACTICE PAPER 2", "pdf": geom2 },
        { "id": 9, "heading": "X SSC HISTORY PRACTICE PAPER 1", "pdf": hist1 },
        { "id": 10, "heading": "X SSC HISTORY PRACTICE PAPER 2", "pdf": hist2 },
        { "id": 11, "heading": "X SSC MARATHI PRACTICE PAPER 1", "pdf": mar1 },
        { "id": 12, "heading": "X SSC MARATHI PRACTICE PAPER 2", "pdf": mar2 },
        { "id": 13, "heading": "X SSC FRENCH PRACTICE PAPER 1", "pdf": fre1 },
        { "id": 14, "heading": "X SSC FRENCH PRACTICE PAPER 2", "pdf": fre2 },
        { "id": 15, "heading": "X SSC HINDI 50 (PAPER 1)", "pdf": hindi1 },
        { "id": 16, "heading": "X SSC HINDI 50 (PAPER 2)", "pdf": hindi2 },
        { "id": 17, "heading": "X SSC HINDI 100 (PAPER 1)", "pdf": hindi3 },
        { "id": 18, "heading": "X SSC HINDI 100 (PAPER 2)", "pdf": hindi4 },
        { "id": 19, "heading": "X SSC SANSKRIT 50 (PAPER 1)", "pdf": san1 },
        { "id": 20, "heading": "X SSC SANSKRIT 100 (PAPER 1)", "pdf": san2 },
        { "id": 21, "heading": "X SSC SANSKRIT 100 (PAPER 2)", "pdf": san3 },
        { "id": 22, "heading": "X SSC SCIENCE 1 (PAPER 1)", "pdf": sci1 },
        { "id": 23, "heading": "X SSC SCIENCE 1 (PAPER 2)", "pdf": sci2 },
        { "id": 24, "heading": "X SSC SCIENCE 2 (PAPER 1)", "pdf": sci3 },
        { "id": 25, "heading": "X SSC SCIENCE 2 (PAPER 2)", "pdf": sci4 },
    ])
    return (
        <>
            <div className='papermaindiv'>
                {data.map((item, index) => {
                    return (
                        <a href={item.pdf} target='_blank'>
                            <div className='papersection'>
                                <h1 className='paperheading'>{item.heading}</h1>
                            </div>
                        </a>
                    )
                })}

            </div>
        </>
    )
}
